import * as React from "react";
import Stack from "@mui/material/Stack";
import {
    Edit,
    SimpleForm,
    Show,
    TextField,
    RichTextField,
    BooleanField,
    TopToolbar,
    Toolbar,
    ListButton,
    Button,
    useRecordContext,
    EmailField,
    UrlField,
    Tab,
    TabbedShowLayout,
    TextInput,
    SaveButton,
    BooleanInput,
    // eslint-disable-next-line
    CheckboxGroupInput,
    RadioButtonGroupInput,
    // ImageInput,
    // ImageField
} from "react-admin";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import Typography from "@mui/material/Typography";
import CopyToClipboardButton from "../../common/CopyToClippboardButton";
import TipsFilteredList from "../../resources/tips/FilteredList";
import ShowsFilteredList from "../../resources/posts/FilteredList";
import EventsFilteredList from "../../resources/events/FilteredList";
import firebaseClient from "../../../utils/FirebaseClient";
import { isB2B } from "../../../utils/HelperFunctions";

const MainTitle = () => {
    const record = useRecordContext();
    // the record can be empty while loading
    if (!record) return "[resource_name] #[record_id]";
    return <span>User: "{record.username}" #{record.id}</span>;
};

const ProfilePicture = (props) => {
    const record = useRecordContext();
    return (
        <div className='profile-image-flex'>
            <img
                src={record.profile_picture2 ? record.profile_picture2 : record.profile_picture ? record.profile_picture : null}
                alt='profile'
                loading="lazy"
                className="profile-picture"
            />
            {props.title ? <span>Previous profile picture</span> : null}
        </div>
    );
};

const Tenants = (props) => {
    const record = useRecordContext();
    return (
        <div>
            {record && record.tenants && record.tenants.length && record.tenants.map && props.tenants && props.tenants.length ?
                record.tenants.map((item, index) => {
                    let tenant = props.tenants.find(t => t && t.id && t.id === item);

                    if (tenant && tenant.id && tenant.name) {
                        return (
                            <div key={index} className="data-cell__copy-to-clipboard">
                                <span>{tenant.name}</span>
                                <CopyToClipboardButton string source={tenant.id} className="launch-link" />
                            </div>
                        )
                    }
                    return item;
                })
                : "none"
            }
        </div>
    );
};

const ProfileBanner = (props) => {
    const record = useRecordContext();
    return (
        <div className='profile-image-flex'>
            <img
                src={record.profile_picture1 ? record.profile_picture1 : record.profile_picture ? record.profile_picture : null}
                alt='banner'
                loading="lazy"
                className="profile-picture-banner"
            />
            {props.title ? <span>Previous profile banner</span> : null}
        </div>
    );
};

export const UserShow = (props) => {
    useEffect(() => {
        // console.log("Scrolling...", props);
        window.scrollTo({ top: 0, left: 0 });
    }, []);

    const [editForm, setEditForm] = useState(false);
    const [tenants, setTenants] = useState([]);
    // eslint-disable-next-line 
    const [loaded, setLoaded] = useState(false);
    // eslint-disable-next-line 
    const [error, setError] = useState(false);

    useEffect(() => {
        firebaseClient.getAllSearchObject({
            collection: `/tenants`,
            where: {
                fieldPath: 'active',
                optStr: '==',
                value: true
            }
        }).then((doc) => {
            // console.log("onTenantsUpdate", doc);
            let tenants = doc && doc.data && doc.data.length ? doc.data : [];
            setTenants(tenants);
            setLoaded(true);
        })
            .catch(e => {
                setError(e.message);
                console.error(e);
            });
        return () => {
        };
    }, []);

    const UserShowActions = ({ basePath, data, resource }) => {
        return (
            <TopToolbar>
                <ListButton basePath={basePath} />
                {/* <SetAdminButton record={record} color="primary" /> */}
                <Button
                    label={`${editForm ? "hide" : "show"
                        } edit form`}
                    onClick={() => setEditForm(!editForm)}
                    startIcon={<EditIcon />}
                />
            </TopToolbar>
        );
    };

    const UserEditToolbar = (props) => (
        <Toolbar {...props}>
            <SaveButton />
        </Toolbar>
    );

    return <Show actions={<UserShowActions />} title={<MainTitle />} {...props}>
        <>
            <TabbedShowLayout>
                <Tab label="User">
                    {editForm ?
                        <Edit actions={null}>
                            <SimpleForm toolbar={<UserEditToolbar />}>
                                <Stack direction="row" sx={{ width: "100%" }} spacing={2}>
                                    <TextInput
                                        sx={{ width: "50%", marginTop: "0" }}
                                        source="first_name" label="First name"
                                        defaultValue=""
                                        resettable={true}
                                    />
                                    <TextInput
                                        sx={{ width: "50%", marginTop: "0" }}
                                        source="last_name" label="Last name"
                                        defaultValue=""
                                        resettable={true}
                                    />
                                </Stack>
                                <Stack direction="row" sx={{ width: "100%" }} spacing={2}>
                                    <TextInput
                                        sx={{ width: "50%", marginTop: "0" }}
                                        source="email"
                                        label="Email"
                                        disabled
                                    />
                                    <TextInput
                                        sx={{ width: "50%", marginTop: "0" }}
                                        source="phone_number"
                                        label="Number (+1, followed by 10 digits)"
                                        defaultValue=""
                                        resettable={true}
                                    />
                                </Stack>
                                <Stack direction="row" sx={{ width: "100%" }} spacing={2}>
                                    <BooleanInput
                                        source={isB2B() ? 'eventAdmin' : 'broadcaster'}
                                        sx={{ width: "50%", marginTop: "0" }}
                                        label="Creator"
                                        defaultValue={false}
                                        resettable={true}
                                    />
                                    <BooleanInput
                                        source="administrator"
                                        sx={{ width: "50%", marginTop: "0" }}
                                        label="Administrator"
                                        defaultValue={false}
                                        resettable={true}
                                    />
                                </Stack>
                                <Stack direction="row" sx={{ width: "100%" }} spacing={2}>
                                    <BooleanInput
                                        source="ambassador"
                                        sx={{ width: "50%", marginTop: "0" }}
                                        label="Ambassador"
                                        defaultValue={false}
                                        resettable={true}
                                    />
                                    <BooleanInput
                                        source="premium"
                                        sx={{ width: "50%", marginTop: "0" }}
                                        label="Premium"
                                        defaultValue={false}
                                        resettable={true}
                                    />
                                </Stack>
                                <Stack
                                    direction="row"
                                    sx={{ width: "100%" }}
                                    spacing={2}
                                >
                                    <TextInput
                                        sx={{ width: "50%", marginTop: "15px" }}
                                        source="username"
                                        label="Username / Alias"
                                        defaultValue=""
                                        resettable={true}
                                    />
                                    <div style={{ width: "50%", marginTop: "0" }}>
                                        <RadioButtonGroupInput
                                            sx={{ marginTop: '0', marginBottom: '0', '& .css-izmnqk-MuiFormGroup-root': { width: '100%' } }}
                                            source="tenants"
                                            choices={tenants}
                                            label="Tenants"
                                        />
                                    </div>
                                </Stack>
                                {/* <Stack direction="row" sx={{ width: "100%" }} spacing={2}>
                                    <ImageInput
                                        sx={{
                                            '& .RaFileInput-dropZone': { padding: 0 },
                                            '&': { width: "50%", marginTop: "0" }
                                        }}
                                        source="profile_picture2"
                                        label="Profile image"
                                        accept="image/png,image/jpeg,image/svg+xml"
                                        defaultValue=""
                                        resettable={true}
                                    >
                                        <ImageField
                                            source="src"
                                            sx={{ '& .RaImageField-image': { width: 88, height: 88, objectFit: 'cover' } }}
                                        />
                                    </ImageInput>
                                    <ProfilePicture title />
                                </Stack>
                                <Stack direction="row" sx={{ width: "100%" }} spacing={2}>
                                    <ImageInput
                                        sx={{
                                            '& .RaFileInput-dropZone': { padding: 0 },
                                            '&': { width: "50%", marginTop: "0" }
                                        }}
                                        source="profile_picture1"
                                        label="Profile banner"
                                        accept="image/png,image/jpeg,image/svg+xml"
                                        defaultValue=""
                                        resettable={true}
                                    >
                                        <ImageField
                                            source="src"
                                            sx={{ '& .RaImageField-image': { width: 166, height: 88, objectFit: 'cover' } }}
                                        />
                                    </ImageInput>
                                    <ProfileBanner title />
                                </Stack> */}
                                <Stack direction="row" sx={{ width: "100%" }} spacing={2}>
                                    <TextInput
                                        sx={{ width: "50%", marginTop: "0" }}
                                        source="instagram"
                                        label="Instagram"
                                        defaultValue=""
                                        resettable={true}
                                    />
                                    <TextInput
                                        sx={{ width: "50%", marginTop: "0" }}
                                        source="twitter"
                                        label="Twitter"
                                        defaultValue=""
                                        resettable={true}
                                    />
                                </Stack>
                                <Stack direction="row" sx={{ width: "100%" }} spacing={2}>
                                    <TextInput
                                        sx={{ width: "50%", marginTop: "0" }}
                                        source="linkedIn"
                                        label="LinkedIn"
                                        defaultValue=""
                                        resettable={true}
                                    />
                                    {process.env.REACT_APP_build && process.env.REACT_APP_build === 'nfl-aa' ?
                                        <TextInput
                                            sx={{ width: "50%", marginTop: "0" }}
                                            source="archiveLink"
                                            label="Archive"
                                            defaultValue=""
                                            resettable={true}
                                        />
                                        : <div style={{ width: "50%", marginTop: "0" }} />
                                    }
                                </Stack>
                            </SimpleForm>
                        </Edit>
                        :
                        <TableContainer
                            sx={{
                                marginTop: "20px",
                                marginBottom: "20px",
                            }}
                            component={Paper}
                        >
                            <Table size="small">
                                <TableRow>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{ width: 300 }}
                                    >
                                        <Typography component="span" variant="body2">
                                            ID
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <div className="data-cell__copy-to-clipboard">
                                            <TextField source="id" />{" "}
                                            <CopyToClipboardButton source="id" />
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{ width: 300 }}
                                    >
                                        <Typography component="span" variant="body2">
                                            Tenants
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <Tenants tenants={tenants} />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{ width: 300 }}
                                    >
                                        <Typography component="span" variant="body2">
                                            Username / Alias
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <TextField source="username" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{ width: 300 }}
                                    >
                                        <Typography component="span" variant="body2">
                                            First name
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <TextField source="first_name" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{ width: 300 }}
                                    >
                                        <Typography component="span" variant="body2">
                                            Last name
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <TextField source="last_name" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{ width: 300 }}
                                    >
                                        <Typography component="span" variant="body2">
                                            Email
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <EmailField source="email" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{ width: 300 }}
                                    >
                                        <Typography component="span" variant="body2">
                                            Number
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <TextField source="phone_number" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{ width: 300 }}
                                    >
                                        <Typography component="span" variant="body2">
                                            Biography
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <RichTextField source="biographyText" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{ width: 300 }}
                                    >
                                        <Typography component="span" variant="body2">
                                            Profile
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <UrlField source="sharedLink" target="_blank" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{ width: 300 }}
                                    >
                                        <Typography component="span" variant="body2">
                                            Profile image
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <ProfilePicture />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{ width: 300 }}
                                    >
                                        <Typography component="span" variant="body2">
                                            Profile banner
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <ProfileBanner />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{ width: 300 }}
                                    >
                                        <Typography component="span" variant="body2">
                                            Creator
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <BooleanField
                                            source={isB2B() ? 'eventAdmin' : 'broadcaster'}
                                            label={"Creator"}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{ width: 300 }}
                                    >
                                        <Typography component="span" variant="body2">
                                            Administrator
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <BooleanField
                                            source="administrator"
                                            label={"Administrator"}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{ width: 300 }}
                                    >
                                        <Typography component="span" variant="body2">
                                            Ambassador
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <BooleanField
                                            source="ambassador"
                                            label={"Ambassador"}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{ width: 300 }}
                                    >
                                        <Typography component="span" variant="body2">
                                            Premium
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <BooleanField
                                            source="premium"
                                            label={"Premium"}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{ width: 300 }}
                                    >
                                        <Typography component="span" variant="body2">
                                            Instagram
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <UrlField source="instagram" target="_blank" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{ width: 300 }}
                                    >
                                        <Typography component="span" variant="body2">
                                            Twitter
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <UrlField source="twitter" target="_blank" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{ width: 300 }}
                                    >
                                        <Typography component="span" variant="body2">
                                            LinkedIn
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <UrlField source="linkedIn" target="_blank" />
                                    </TableCell>
                                </TableRow>
                                {process.env.REACT_APP_build && process.env.REACT_APP_build === 'nfl-aa' ?
                                    <TableRow>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            sx={{ width: 300 }}
                                        >
                                            <Typography component="span" variant="body2">
                                                Archive
                                            </Typography>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <UrlField source="archiveLink" target="_blank" />
                                        </TableCell>
                                    </TableRow>
                                    : null
                                }
                            </Table>
                        </TableContainer>
                    }
                </Tab>

                {!isB2B() ?
                    <Tab label="Received tips">
                        <TableContainer
                            sx={{
                                marginTop: "20px",
                                marginBottom: "20px",
                            }}
                            component={Paper}
                        >
                            <TipsFilteredList source={'id'} fieldName={'authorId'} />
                        </TableContainer>
                    </Tab>
                    : null
                }
                {!isB2B() ?
                    <Tab label="Sent tips">
                        <TableContainer
                            sx={{
                                marginTop: "20px",
                                marginBottom: "20px",
                            }}
                            component={Paper}
                        >
                            <TipsFilteredList source={'id'} fieldName={'userId'} />
                        </TableContainer>
                    </Tab>
                    : null
                }
                <Tab label="Shows">
                    <TableContainer
                        sx={{
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                        component={Paper}
                    >
                        <ShowsFilteredList source={'id'} fieldName={'uid'} />
                    </TableContainer>
                </Tab>

                <Tab label="Calendar">
                    <TableContainer
                        sx={{
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                        component={Paper}
                    >
                        <EventsFilteredList source={'id'} fieldName={'uid'} />
                    </TableContainer>
                </Tab>

            </TabbedShowLayout>
        </>
    </Show>
};