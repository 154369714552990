export const appLogo = process.env.REACT_APP_build && process.env.REACT_APP_build === 'nfl-aa' ?
    "https://firebasestorage.googleapis.com/v0/b/nflalumnimedia-com.appspot.com/o/nfl-aa.ico?alt=media&token=4f5caec2-2aeb-4ea4-b591-367c0fb3d461"
    : process.env.REACT_APP_build && (process.env.REACT_APP_build === 'b2b-dev' || process.env.REACT_APP_build === 'b2b-prod') ?
        "https://firebasestorage.googleapis.com/v0/b/streemz-net.appspot.com/o/favicon.ico?alt=media&token=05af6aa5-3b68-4e18-b87f-b905b875ca6e"
        : "https://firebasestorage.googleapis.com/v0/b/nofilterbroadcast-net.appspot.com/o/NoFilterNet.ico?alt=media&token=184fdd14-0337-4923-a69f-e9bf79b188fa";
export const appTitle = process.env.REACT_APP_build && process.env.REACT_APP_build === 'nfl-aa' ?
    "NFL Alumni Media Control Panel"
    : process.env.REACT_APP_build && process.env.REACT_APP_build === 'b2b-prod' ?
        "Strmz.io Control Panel"
        : process.env.REACT_APP_build && process.env.REACT_APP_build === 'b2b-dev' ?
            "Strmz.io DEV Control Panel"
            : "No Filter Control Panel";
export const domain = process.env.REACT_APP_build && process.env.REACT_APP_build === 'nfl-aa' ?
    "cp.nflalumnimedia.com"
    : process.env.REACT_APP_build && process.env.REACT_APP_build === 'b2b-prod' ?
        "cp.strmz.io"
        : process.env.REACT_APP_build && process.env.REACT_APP_build === 'b2b-dev' ?
            "cp-dev.strmz.io"
            : "nofilterbroadcast-net-cp";

export function msToHhMm(ms) {
    // 1- Convert to seconds:
    let seconds = (ms || 0) / 1000;
    // console.log('++++++++++', ms, seconds)
    // 2- Extract hours:
    const hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
    seconds = seconds % 3600; // seconds remaining after extracting hours
    // 3- Extract minutes:
    const minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
    // 4- Keep only seconds not extracted to minutes:
    seconds = seconds % 60;
    return `${hours} hours ${minutes} minutes`
}

export function hoursToMS(val) {
    let parseVal = parseInt(val);
    if (val) {
        parseVal = parseVal * 60 * 60 * 1000;
        return parseVal
    } else return 0;
}

export function getTicketStore(id) {
    let retVal = 'N/A';

    if (id && (id.indexOf('ch_') !== -1 || id.indexOf('pi_') !== -1)) {
        retVal = 'stripe';
    } else if (id && /^\d+$/.test(id)) {
        retVal = 'apple';
    }

    return retVal;
}

export function getTenantId(path) {
    let retVal = path;

    if (process.env.REACT_APP_tenantId && path) {
        retVal = `tenants/${process.env.REACT_APP_tenantId}/${path}`;
    }

    return retVal;
}

export function isB2B() {
    let retVal = false;

    if (process.env.REACT_APP_build && (process.env.REACT_APP_build === 'b2b-dev' || process.env.REACT_APP_build === 'b2b-prod')) {
        retVal = true;
    }

    return retVal;
}

export function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export const prices = [
    { id: 0, name: 0 }, { id: 0.99, name: 0.99 }, { id: 1.99, name: 1.99 }, { id: 2.99, name: 2.99 }, { id: 3.99, name: 3.99 }, { id: 4.99, name: 4.99 }, { id: 9.99, name: 9.99 },
    { id: 14.99, name: 14.99 }, { id: 19.99, name: 19.99 }, { id: 24.99, name: 24.99 }, { id: 49.99, name: 49.99 }, { id: 74.99, name: 74.99 }, { id: 99.99, name: 99.99 },
    { id: 124.99, name: 124.99 }, { id: 149.99, name: 149.99 }, { id: 199.99, name: 199.99 }, { id: 249.99, name: 249.99 }, { id: 299.99, name: 299.99 },
    { id: 399.99, name: 399.99 }, { id: 499.99, name: 499.99 }, { id: 699.99, name: 699.99 }, { id: 999.99, name: 999.99 }
];

export const numTickets = [
    { id: 10, name: 10 }, { id: 50, name: 50 }, { id: 100, name: 100 }, { id: 200, name: 200 }, { id: 500, name: 500 },
    { id: 1000, name: 1000 }, { id: 5000, name: 5000 }, { id: 10000, name: 10000 }, { id: 25000, name: 25000 }, { id: 50000, name: 50000 }
];

export const estDurations = [
    { id: 15, name: '15+ mins' },
    { id: 30, name: '30+ mins' },
    { id: 45, name: '45+ mins' },
    { id: 60, name: '60+ mins' },
    { id: 90, name: '90+ mins' },
    { id: 120, name: '120+ mins' },
    { id: 180, name: '180+ mins' }
]

export const expireTimes = [
    { id: '1 d', name: '1 day' },
    { id: '2 d', name: '2 days' },
    { id: '1 w', name: '1 week' },
    { id: '2 w', name: '2 weeks' },
    { id: '1 M', name: '1 month' },
    { id: '6 M', name: '6 months' }
]

export const visibilityArray = [
    { id: "public", name: "Public" },
    { id: "private", name: "Private" }
]

export const layouts = [
    { id: 'ad-participants', name: 'Ad+Participants' },
    { id: 'speaker', name: 'Ad+Active Speaker' },
    { id: 'no-crop', name: 'Zoom out' },
    { id: 'crop', name: 'Zoom in' }

]

export const videoCodecs = [
    { id: "VP8", name: "VP8" },
    { id: "VP9", name: "VP9" }
]

export const resolutions = [
    { id: "720p", name: "HDReady" },
    { id: "1080p", name: "FullHD" }
]

function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
}

export function convertMsToTime(milliseconds) {
    if (!milliseconds) return "";
    try {
        let seconds = Math.floor(milliseconds / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);

        seconds = seconds % 60;
        minutes = minutes % 60;

        // If you don't want to roll hours over, e.g. 24 to 00
        // comment (or remove) the line below
        // commenting next line gets you `24:00:00` instead of `00:00:00`
        // or `36:15:31` instead of `12:15:31`, etc.
        hours = hours % 24;

        return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
            seconds
        )}`;
    } catch (e) {
        return "(Unknown)";
    }
}

export function formatDateTime(ms) {
    if (!ms) return "";
    try {
        return new Date(ms).toISOString();
    } catch (e) {
        return "(unknown)";
    }
}

export function renderDuration(record) {
    if ((!record) || ((record && record.duration && record.duration < 10) && (record && !record.recordingDuration) && (record && record.stats && !record.stats.duration) && (record && record.stats && (!record.stats.started || !record.stats.stopped))))
        return "00:00:00"

    let duration = convertMsToTime(record.duration * 1000) || "00:00:00";

    if (record && record.recordingDuration) {
        duration = convertMsToTime(record.recordingDuration * 1000);
    } else if (record && record.stats && record.stats.duration) {
        duration = convertMsToTime(record.stats.duration);
    } else if (record && record.stats && record.stats.started && record.stats.stopped) {
        duration = convertMsToTime(record.stats.stopped - record.stats.started);
    }

    return `${duration}`;
}