import * as React from "react";
import { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography"
import EditIcon from '@mui/icons-material/Edit';
import {
    SimpleForm,
    TextInput,
    SelectInput,
    NumberInput,
    BooleanInput,
    required,
    regex,
    Toolbar,
    SaveButton,
    useRefresh,
    Button,
    ImageField,
    maxLength
} from "react-admin";
import firebaseClient from "../../../utils/FirebaseClient";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
    prices,
    numTickets,
    estDurations,
    expireTimes,
    visibilityArray,
    layouts,
    videoCodecs,
    resolutions
} from "../../../utils/HelperFunctions";
import CropperFile from '../../common/CropperFile';


export const DefaultsList = () => {
    const refresh = useRefresh();

    const [doc, setDoc] = useState(null);
    const [categories, setCategories] = useState([]);
    const [loaded, setLoaded] = useState(false);
    // eslint-disable-next-line
    const [error, setError] = useState(false);

    const [homeBannerImg, setHomeBannerImg] = useState(null);
    const [bannerImg, setBannerImg] = useState(null);


    const [openHomeBanner, setOpenHomeBanner] = React.useState(false);
    const [openBanner, setOpenBanner] = React.useState(false);

    const onDocUpdate = async (defaults) => {
        console.log("onDocUpdate defaults", defaults);

        if (defaults) {
            let banners = defaults.banners && defaults.banners.length ? defaults.banners : [], banner = null;

            if (banners && (typeof banners === 'string' || banners instanceof String)) {
                try {
                    banners = JSON.parse(banners);
                } catch (error) {
                    console.error('Error to parse banners', error);
                }
            }

            if (banners && banners.length) {
                banner = banners.filter(item => item.default)[0];

                if (!banner) {
                    banner = banners[0];
                }

                setBannerImg(banner.img);
                defaults.bannerLink = banner.link;
            }

            if (defaults.homeBannerImg) {
                setHomeBannerImg(defaults.homeBannerImg)
            }

            setDoc(defaults);
        }
        setLoaded(true);
    };

    const onCategotyUpdate = async (value) => {
        // console.log("onCategotyUpdate", value);
        if (value) {
            let categories = [];
            try {
                let parseCategories = JSON.parse(value);
                if (parseCategories && parseCategories.length) {
                    parseCategories.map((item, i) => {
                        categories.push({ id: item.id, name: item.title });
                        return i;
                    })
                    setCategories(categories);
                }
            } catch (e) {
                setCategories(categories);
            }
        }
    };

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
        firebaseClient.getRTData('/public/defaults')
            .then(onDocUpdate)
            .catch((e) => {
                setError(e.message);
                console.error(e);
            });
        firebaseClient.getRTData('/public/categories')
            .then(onCategotyUpdate)
            .catch((e) => {
                setError(e.message);
                console.error(e);
            });
    }, []);

    // eslint-disable-next-line
    const validateLink = regex(/(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/, 'Must be a valid link');

    const DefaultsToolbar = props => (
        <Toolbar {...props} sx={{
            "& ": {
                background: "#383838",
            },
        }}>
            <Stack direction="horizontal" sx={{ "& ": { justifyContent: 'space-between', width: "100%" } }}>
                <SaveButton alwaysEnable label="Save" />
            </Stack>
        </Toolbar>
    );

    const handleSubmit = async (data) => {
        console.log("handleSubmit", data);

        let oldBanners = [], oldDefaultBanner = {}, newBanner = {};

        if (data && data.banners && data.banners.length) {
            oldBanners = data.banners;
        }

        if (oldBanners && (typeof oldBanners === 'string' || oldBanners instanceof String)) {
            try {
                oldBanners = JSON.parse(oldBanners);
            } catch (error) {
                console.error('Error to parse oldBanners', error);
            }
        }

        if (oldBanners && oldBanners.length) {
            oldDefaultBanner = oldBanners.filter(item => item.default)[0];

            if (!oldDefaultBanner) {
                oldDefaultBanner = oldBanners[0];
            }
        }

        newBanner = Object.assign(oldDefaultBanner, {
            id: 'default-banner',
            img: bannerImg,
            link: data.bannerLink,
            default: true
        });

        data.banners = JSON.stringify([newBanner]);
        data.homeBannerImg = homeBannerImg;

        delete data.bannerLink;

        console.log("handleSubmit update", data);

        await firebaseClient.crudRT('update', 'public/defaults', data);

        refresh();
    };

    return (
        <div>
            {loaded ?
                <div>
                    <Dialog open={openHomeBanner} onClose={() => setOpenHomeBanner(false)}>
                        <DialogTitle sx={{ textAlign: "center", paddingTop: '30px', minWidth: '360px' }}>
                            Home Banner:
                        </DialogTitle>
                        <DialogContent sx={{
                            "& ": {
                                paddingTop: '0',
                                paddingBottom: '30px',
                            },
                            "& .MuiFormLabel-root": {
                                display: 'none',
                            },
                            "& .MuiFormHelperText-root": {
                                display: 'none',
                            },
                            "& .MuiFormControl-root": {
                                paddingBottom: '20px',
                            }
                        }}>
                            <div style={{ paddingBottom: '16px', width: '100%' }}>
                                <CropperFile
                                    file={homeBannerImg ? homeBannerImg : null}
                                    uid='homeBanner'
                                    handleAddImage={(image) => {
                                        console.log('handleAddImage home banner', image)
                                        setHomeBannerImg(image);
                                    }}
                                />
                            </div>
                            <Button
                                onClick={() => setOpenHomeBanner(false)}
                                sx={{
                                    "& ": {
                                        margin: '0 auto',
                                        display: 'block'
                                    },
                                    "& .MuiButton-startIcon": {
                                        margin: 0
                                    }
                                }}
                            >
                                back
                            </Button>
                        </DialogContent>
                    </Dialog>
                    <Dialog open={openBanner} onClose={() => setOpenBanner(false)}>
                        <DialogTitle sx={{ textAlign: "center", paddingTop: '30px', minWidth: '360px' }}>
                            Banner:
                        </DialogTitle>
                        <DialogContent sx={{
                            "& ": {
                                paddingTop: '0',
                                paddingBottom: '30px',
                            },
                            "& .MuiFormLabel-root": {
                                display: 'none',
                            },
                            "& .MuiFormHelperText-root": {
                                display: 'none',
                            },
                            "& .MuiFormControl-root": {
                                paddingBottom: '20px',
                            }
                        }}>
                            <div style={{ paddingBottom: '16px', width: '100%' }}>
                                <CropperFile
                                    file={bannerImg ? bannerImg : null}
                                    uid='banners'
                                    handleAddImage={(image) => {
                                        console.log('handleAddImage banner', image)
                                        setBannerImg(image);
                                    }}
                                />
                            </div>
                            <Button
                                onClick={() => setOpenBanner(false)}
                                sx={{
                                    "& ": {
                                        margin: '0 auto',
                                        display: 'block'
                                    },
                                    "& .MuiButton-startIcon": {
                                        margin: 0
                                    }
                                }}
                            >
                                back
                            </Button>
                        </DialogContent>
                    </Dialog>
                    <SimpleForm
                        toolbar={<DefaultsToolbar />}
                        onSubmit={handleSubmit}
                        record={doc}
                        sx={{
                            "& ": {
                                paddingTop: '20px',
                                width: '100%'
                            }
                        }}
                    >
                        <Stack direction="column"
                            sx={{
                                "& ": {
                                    width: '100%'
                                }
                            }}
                        >
                            <Typography
                                variant="h7"
                                gutterBottom
                                sx={{
                                    "& ": {
                                        marginBottom: '15px'
                                    }
                                }}
                            >
                                Anonymous Watchers
                            </Typography>
                            <Stack direction="row" spacing={2}>
                                <NumberInput
                                    source='anonymousWatchersCounterTime'
                                    label='Anonymous watchers counter time (seconds)'
                                    fullWidth
                                    validate={required()}
                                />
                                <NumberInput
                                    source='anonymousWatchersEndTime'
                                    label='Anonymous watchers end time (minutes)'
                                    fullWidth
                                    validate={required()}
                                />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <NumberInput
                                    source='anonymousWatchersLoginTime'
                                    label='Anonymous watchers login time (seconds)'
                                    fullWidth
                                    validate={required()}
                                />
                                <NumberInput
                                    source='anonymousWatchersWarnTime'
                                    label='Anonymous watchers warn time (minutes)'
                                    fullWidth
                                    validate={required()}
                                />
                            </Stack>
                            <Typography
                                variant="h7"
                                gutterBottom
                                sx={{
                                    "& ": {
                                        marginBottom: '15px'
                                    }
                                }}
                            >
                                Rotator
                            </Typography>
                            <Stack direction="row" spacing={2}>
                                <NumberInput
                                    source='rotationTime'
                                    label='Rotator time (seconds)'
                                    fullWidth
                                    resettable
                                    validate={required()}
                                />
                            </Stack>
                            <Typography
                                variant="h7"
                                gutterBottom
                                sx={{
                                    "& ": {
                                        marginBottom: '15px'
                                    }
                                }}
                            >
                                Home Banner
                            </Typography>
                            <Stack direction="row" spacing={2}>
                                <BooleanInput
                                    source='showHomeBanner'
                                    label='Show home banner'
                                    fullWidth
                                />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <div style={{ width: '50%' }}>
                                    <p style={{ fontSize: '12px', marginTop: 0, marginBottom: 5 }}>Home banner image (click to replace)</p>
                                    {homeBannerImg ?
                                        <ImageField
                                            source='homeBannerImg'
                                            record={{ homeBannerImg }}
                                            label='Click to replace'
                                            fullWidth
                                            onClick={() => setOpenHomeBanner(true)}
                                            sx={{
                                                "& ": {
                                                    paddingBottom: '10px',
                                                    width: '100%',
                                                    height: 'auto',
                                                    cursor: 'pointer'
                                                },
                                                '& .RaImageField-image': {
                                                    width: '100%',
                                                    margin: 0,
                                                    height: 'auto'
                                                }
                                            }}
                                        />
                                        :
                                        <div>
                                            <span style={{ fontSize: '12px' }}>none</span>
                                            <button
                                                className="copy-to-clipboard-btn"
                                                onClick={() => setOpenHomeBanner(true)}
                                            >
                                                <EditIcon fontSize="inherit" />
                                            </button>
                                        </div>}
                                </div>
                                <TextInput
                                    source='homeBannerLink'
                                    label='Home banner link'
                                    fullWidth
                                    resettable
                                    validate={validateLink}
                                    sx={{
                                        "& ": {
                                            width: '50%',
                                            marginTop: '30px !important',
                                            marginBottom: 'auto !important'
                                        }
                                    }}
                                />
                            </Stack>
                            <Typography
                                variant="h7"
                                gutterBottom
                                sx={{
                                    "& ": {
                                        marginBottom: '15px'
                                    }
                                }}
                            >
                                Event Scheduler
                            </Typography>
                            <Stack direction="row" spacing={2}>
                                <SelectInput
                                    source="archivePrice"
                                    label='Archive price'
                                    fullWidth
                                    validate={required()}
                                    choices={prices}
                                    sx={{
                                        "& ": {
                                            marginTop: 0
                                        }
                                    }}
                                />
                                <SelectInput
                                    source="price"
                                    label='Price'
                                    fullWidth
                                    validate={required()}
                                    choices={prices}
                                />
                                <SelectInput
                                    source="tipsValue"
                                    label='Tips value'
                                    fullWidth
                                    validate={required()}
                                    choices={prices}
                                />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <SelectInput
                                    source='estDuration'
                                    label='Estimate duration'
                                    fullWidth
                                    validate={required()}
                                    choices={estDurations}
                                    sx={{
                                        "& ": {
                                            marginTop: 0
                                        }
                                    }}
                                />
                                <SelectInput
                                    source="numTicket"
                                    label='Tickets'
                                    fullWidth
                                    validate={required()}
                                    choices={numTickets}
                                />
                                <SelectInput
                                    source='category'
                                    label='Category'
                                    fullWidth
                                    validate={required()}
                                    choices={categories}
                                />
                            </Stack>
                            <Stack direction="row" spacing={4}>
                                <BooleanInput
                                    source='customCoverShow'
                                    label='Show custom cover'
                                    fullWidth
                                />
                                <BooleanInput
                                    source='tipsShow'
                                    label='Tips'
                                    fullWidth
                                />
                                <BooleanInput
                                    source='featured'
                                    label='Featured'
                                    fullWidth
                                />
                                <BooleanInput
                                    source='watchLater'
                                    label='Recording available to watch later'
                                    fullWidth
                                />
                                <BooleanInput
                                    source='tvRating'
                                    label='Mature content'
                                    fullWidth
                                />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <div style={{ width: '50%' }}>
                                    <p style={{ fontSize: '12px', marginTop: 0, marginBottom: 5 }}>Banner image (click to replace)</p>
                                    {bannerImg ?
                                        <ImageField
                                            source='bannerImg'
                                            record={{ bannerImg }}
                                            label='Click to replace'
                                            fullWidth
                                            onClick={() => setOpenBanner(true)}
                                            sx={{
                                                "& ": {
                                                    paddingBottom: '10px',
                                                    width: '100%',
                                                    height: 'auto',
                                                    cursor: 'pointer'
                                                },
                                                '& .RaImageField-image': {
                                                    width: '100%',
                                                    margin: 0,
                                                    height: 'auto'
                                                }
                                            }}
                                        />
                                        :
                                        <div>
                                            <span style={{ fontSize: '12px' }}>none</span>
                                            <button
                                                className="copy-to-clipboard-btn"
                                                onClick={() => setOpenBanner(true)}
                                            >
                                                <EditIcon fontSize="inherit" />
                                            </button>
                                        </div>
                                    }
                                </div>
                                <TextInput
                                    source='bannerLink'
                                    label='Banner link'
                                    fullWidth
                                    resettable
                                    sx={{
                                        "& ": {
                                            width: '50%',
                                            marginTop: '30px !important',
                                            marginBottom: 'auto !important'
                                        }
                                    }}
                                    validate={validateLink}
                                />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <TextInput
                                    source='title'
                                    label='Title'
                                    fullWidth
                                    resettable
                                    validate={maxLength('24', 'Max 24 chars')}
                                />
                                <TextInput
                                    source='description'
                                    label='Description'
                                    multiline
                                    fullWidth
                                    resettable
                                />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <SelectInput
                                    source='expireTime'
                                    label='Expire time'
                                    fullWidth
                                    validate={required()}
                                    choices={expireTimes}
                                    sx={{
                                        "& ": {
                                            marginTop: 0
                                        }
                                    }}
                                />
                                <SelectInput
                                    source="visibility"
                                    label='Visibility'
                                    fullWidth
                                    validate={required()}
                                    choices={visibilityArray}
                                />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <SelectInput
                                    source='defaultLayout'
                                    label='Layout'
                                    fullWidth
                                    validate={required()}
                                    choices={layouts}
                                    sx={{
                                        "& ": {
                                            marginTop: 0
                                        }
                                    }}
                                />
                                <SelectInput
                                    source='videoCodec'
                                    label='Video codec'
                                    fullWidth
                                    validate={required()}
                                    choices={videoCodecs}
                                />
                                <SelectInput
                                    source='resolution'
                                    label='Resolution'
                                    fullWidth
                                    validate={required()}
                                    choices={resolutions}
                                />
                            </Stack>
                        </Stack>
                    </SimpleForm>
                </div>
                :
                <p>Loading defaults...</p>}
        </div>
    );
};
