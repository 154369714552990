// in src/App.js
import EventIcon from "@mui/icons-material/Event";
import PostsIcon from "@mui/icons-material/Slideshow";
import GroupIcon from "@mui/icons-material/Group";
import StatsIcon from "@mui/icons-material/QueryStats";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TicketIcon from '@mui/icons-material/ConfirmationNumber';
import DvrIcon from "@mui/icons-material/Dvr";
import SettingsIcon from '@mui/icons-material/Settings';
import MemoryIcon from '@mui/icons-material/Memory';
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import PaymentsIcon from "@mui/icons-material/Payments";
import * as React from "react";
import { Admin, Resource } from "react-admin";
import LoginPage from "./components/pages/LoginPage";
import { EventList } from "./components/resources/events/List";
import { EventShow } from "./components/resources/events/Show";
import { PostsList } from "./components/resources/posts/List";
import { PostsShow } from "./components/resources/posts/Show";
import { TipsList } from "./components/resources/tips/List";
import { TipsShow } from "./components/resources/tips/Show";
import { TicketsList } from "./components/resources/tickets/List";
import { TicketsShow } from "./components/resources/tickets/Show";
import { UserList } from "./components/resources/users/List";
import { UserShow } from "./components/resources/users/Show";
import { TenantList } from "./components/resources/tenants/List";
import { TenantEdit } from "./components/resources/tenants/Edit";
import { TenantCreate } from "./components/resources/tenants/Create";
import { StatsList } from "./components/resources/stats/List";
import { StatsShow } from "./components/resources/stats/Show";
import { FanoutList } from "./components/resources/fanouts/List";
import { FanoutShow } from "./components/resources/fanouts/Show";
import { DefaultsList } from "./components/resources/defaults/List";
import { DefaultsShow } from "./components/resources/defaults/Show";
import { AIList } from "./components/resources/ai/List";
import { AIShow } from "./components/resources/ai/Show";
import { SubscriptionTypesCreate } from "./components/resources/subscriptions/SubscriptionTypesCreate";
import { SubscriptionTypesEdit } from "./components/resources/subscriptions/SubscriptionTypesEdit";
import { SubscriptionTypesList } from "./components/resources/subscriptions/SubscriptionTypesList";
import { UserSubscriptionCreate } from "./components/resources/subscriptions/UserSubscriptionCreate";
import { UserSubscriptionEdit } from "./components/resources/subscriptions/UserSubscriptionEdit";
import { UserSubscriptionList } from "./components/resources/subscriptions/UserSubscriptionList";
import firebaseClient from "./utils/FirebaseClient";
import { isB2B } from './utils/HelperFunctions';

const theme = {
    palette: {
        mode: "dark",
    },
    components: {
        someComponent: {},
    },
};

const App = (props) => (
    <Admin
        dataProvider={props.dataProvider}
        authProvider={firebaseClient.getAuthAdapter()}
        loginPage={LoginPage}
        requireAuth
        theme={theme}
        disableTelemetry
    >
        <Resource
            name="posts"
            list={PostsList}
            show={PostsShow}
            icon={PostsIcon}
            options={{ label: 'Show' }}
        >
        </Resource>
        <Resource
            options={{ label: 'Calendar' }}
            name="events"
            list={EventList}
            show={EventShow}
            icon={EventIcon}
        />
        <Resource
            name="stats"
            list={StatsList}
            show={StatsShow}
            icon={StatsIcon}
        />
        <Resource
            name="users"
            list={UserList}
            show={UserShow}
            icon={GroupIcon}
        />
        {isB2B() ?
            <Resource
                name="user_subscriptions"
                list={UserSubscriptionList}
                edit={UserSubscriptionEdit}
                create={UserSubscriptionCreate}
                icon={PaymentsIcon}
            />
            : null
        }
        {isB2B() ?
            <Resource
                name="subscription_types"
                list={SubscriptionTypesList}
                edit={SubscriptionTypesEdit}
                create={SubscriptionTypesCreate}
                icon={CardMembershipIcon}
            />
            : null
        }
        {isB2B() ?
            <Resource
                name="tenants"
                list={TenantList}
                edit={TenantEdit}
                create={TenantCreate}
                icon={GroupIcon}
            />
            : null
        }
        {!isB2B() ?
            <Resource
                name="tips"
                list={TipsList}
                show={TipsShow}
                icon={AttachMoneyIcon}
            />
            : null
        }
        {!isB2B() ?
            <Resource
                name="tickets"
                list={TicketsList}
                show={TicketsShow}
                icon={TicketIcon}
            />
            : null
        }
        <Resource
            options={{ label: 'VMs' }}
            name="fanouts"
            list={FanoutList}
            show={FanoutShow}
            icon={DvrIcon}
        />
        {!isB2B() ?
            <Resource
                options={{ label: 'Defaults' }}
                name="defaults"
                list={DefaultsList}
                edit={DefaultsList}
                show={DefaultsShow}
                icon={SettingsIcon}
            />
            : null
        }
        {!isB2B() ?
            <Resource
                options={{ label: 'AI' }}
                name="ai"
                list={AIList}
                show={AIShow}
                icon={MemoryIcon}
            />
            : null
        }
    </Admin>
);
export default App;
